$primary: #0D9DDB !default;
$theme2: #FF6C2F;
$theme3: #FFE800;
$theme4: #D84465;
$theme-contrast-color: #fff !default;

@import '@/src/scss/preset/on-campus.scss';
@import "~daterangepicker";

$lg-path-fonts: '~lightgallery/fonts';
$lg-path-images: '~lightgallery/images';
@import "~lightgallery/scss/lightgallery";
@import "~lightgallery/scss/lg-video";
