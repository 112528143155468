// Change layout variables (with "!default" flag)
// Note: please only adjust Bootstrap variables, or make BS-compatible additions
//$enable-rounded: false !default;

@import '../webfonts/alwyn-new-regular.css';
@import '../webfonts/alwyn-new-regular-italic.css';
@import '../webfonts/alwyn-new-light.css';
@import '../webfonts/alwyn-new-light-italic.css';
@import '../webfonts/alwyn-new-medium.css';
@import '../webfonts/alwyn-new-medium-italic.css';

$enable-responsive-font-sizes: true;

$border-width: 1px;
$border-radius: 4px;
$border-radius-lg: 4px;
$btn-border-radius: 4px;
$btn-border-radius-sm: 4px;
$btn-border-radius-lg: 4px;
$theme-colors: (
        'theme2': $theme2,
        'theme3': $theme3,
        'theme4': $theme4
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
        xxxl: 1900px
);

$btn-font-weight: bold;
$headings-font-weight: 600;
$enable-shadows: false;
$link-decoration: underline;
$card-border-color: $primary;


$font-family-base: 'Alwyn New', Helvetica, Arial, Sans-Serif;

@import '@/src/scss/main.scss';

// SCSS Components
@import '@/src/scss/components/mijn-sidebar.scss';  // Mijn omgeving css (sidebar theme)
@import '@/src/scss/components/dropdown-sub-menu.scss';
@import '@/src/scss/components/video-light-gallery.scss';
@import '@/src/scss/components/hero-video.scss'; // Hero video

$owl-image-path: '~owl.carousel/src/img/';
@import "~owl.carousel/src/scss/owl.carousel";

.owl-carousel .owl-item img {
  width: auto;
}
.owl-nav {
  margin-top: -15px;
  button {
    font-size: 4em !important;
  }
}

.owl-dots {
  display: none;
}

.top-bar {
  a {
    color: $white;
  }
}

.gallery-container a {
  width: 540px;
  margin: 5px;
}

.navbar {
  .navbar-brand--img {
    width: 150px;
    @include media-breakpoint-up(lg) {
      width: 100px;
    }
    @include media-breakpoint-up(xl) {
      width: 200px;
    }
  }
  .navbar-brand-responsive--img {
    width: 40px;
  }
}

.shopping-cart-link {
  a {
    color: $dark;
    text-decoration: none;
    @include media-breakpoint-down(md) {
      display: block;
      margin-bottom: 1rem;
    }
  }
}

.hero, .content-hero {
  background-size: cover;
  border-bottom: solid 12px $primary;
  .header--flavour {
    height: 90px;
    position: absolute;
    bottom: -40px;
    right: 0;
  }
}

.video-hero-overlay-opaque {
  background: rgb(0,69,173);
  background: linear-gradient(180deg, rgba(0,69,173,0.2) 0%, rgba(0,69,173,1) 90%);
  @extend .d-block;
  opacity: 0.8 !important;
}

.dark-overlay-opaque {
  background: rgb(39, 47, 63);
  background: linear-gradient(180deg, rgb(39, 47, 63, 0.2) 0%, rgb(39, 47, 63) 90%);
  @extend .d-block;
  opacity: 0.5 !important;
  @extend .position-absolute;
  @extend .w-100;
  @extend .h-100;
}

.banner--duo {
  .rounded-odd {
    border-radius: $border-radius $border-radius $border-radius 2em !important;
  }
  .rounded-even {
    border-radius: $border-radius $border-radius 2em $border-radius !important;
  }
}

.testimonial-banner {
  border-radius: 1em 0 0 1em !important;
}

.img--top {
  border-radius: $border-radius $border-radius 0 0 !important;
}
.banner-img {
  border: 1px solid $primary !important;
  @extend .rounded;
  @extend .my-auto;
}

.head--line::before {
  content:" ";
  position: absolute;
  border-bottom:3px solid $theme2;
  width:40px;
  height:1.3em;
}

.content-hero {
  @include media-breakpoint-up(lg) {
    height: 350px;
  }
  .c-hero-padding {
    padding-top: 3rem;
    @include media-breakpoint-up(lg) {
      padding-top: 4rem;
    }
  }
  padding-bottom: 2rem;
  background-size: cover;
}

.flavor-underline::after {
  content: '';
  height: 3px;
  width: 100%;
  bottom: -4px;
  position: absolute;
  background-color:$theme2;
  left: 0;
}


.flavor1 {
  margin-top: -12rem;
  width: 340px;
  @include media-breakpoint-up(lg) {
    margin-top: -17rem;
  }
}

.bg-gradient {
  background: rgb(35,103,199);
  background: linear-gradient(145deg, rgba(35,103,199,1) 0%, $primary 100%);
}

.bg-dark-gradient {
   background: rgb(56, 56, 56);
   background: linear-gradient(145deg, rgb(56, 56, 56) 0%, rgb(56, 56, 56) 100%);
}

.mt-xl {
  margin-top: 6rem;
}

.round {
  border-radius: 50% !important;
}

.flavor3 {
  width: 300px;
}

.flavor4 {
  right: 15px;
  bottom: 0;
}

.vert-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

footer {
  i {
    color: white;
    font-size: 2.4rem;
  }
  .mobile-social {
    i {
      color: $primary;
    }
  }
}

.c-mt-nxl {
  margin-top: -9rem;
}

.content-bordered-img {
  border: solid 2px $primary;
  max-width: 100%;
}

.card-link {
  position: absolute;
  bottom: .5rem;
}

.card--banner, .banner--duo {
  a {
    text-decoration: none !important;
  }
}

.breadcrumb {
  background-color: $dark;
  .breadcrumb-item {
    color: white;
    a {
      color: white;
    }
  }
}

.accordion {
  .card-header {
    background: $primary;
    .btn-link {
      color: $theme-contrast-color;
    }
  }
}

.shoppingcart--icon {
  @extend .mr-2;
  @extend .my-auto;
}

nav {
  .nav-link {
    color: $dark !important;
  }
}

// Custom parts overview
.part--card {
  padding: 1rem;
  .card-body {
    padding: 1rem 0 0 0;
    .card-title {
      a{
        text-decoration: none;
        color: $dark;
      }
    }
  }
}
